import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

import { firebaseConfig } from "../constants/defaultValues";

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const database = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.functions();

if (process.env.NODE_ENV === "development") {
  if (process.env.REACT_APP_FUNCTIONS_URL) {
    console.log(`Using functions URL: ${process.env.REACT_APP_FUNCTIONS_URL}`);
    firebase
      .functions()
      .useFunctionsEmulator(process.env.REACT_APP_FUNCTIONS_URL);
  }

  if (process.env.REACT_APP_FIRESTORE_HOST_PORT) {
    console.log(
      `Using firestore HOST:PORT: ${process.env.REACT_APP_FIRESTORE_HOST_PORT}`
    );
    database.settings({
      host: process.env.REACT_APP_FIRESTORE_HOST_PORT,
      ssl: false,
    });
  }
}

export { auth, database, storage, functions };
