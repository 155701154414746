import { WellbeingResultsActionTypes } from "./wellbeingResults.types";

const INITIAL_STATE = {};

const wellbeingResultsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case WellbeingResultsActionTypes.FETCH_WELLBEING_RESULTS:
      return { ...state, loading: true };
    case WellbeingResultsActionTypes.FETCH_WELLBEING_RESULTS_SUCCESS:
      return {
        ...state,
        [payload.type]: { ...payload.results },
        loading: false,
      };
    default:
      return state;
  }
};

export default wellbeingResultsReducer;
