import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { database } from "../../helpers/Firebase.js";

import { CatalogActionTypes } from "./catalog.types.js";
import {
  refreshCatalogSuccess,
  refreshCatalogLessonSuccess,
  refreshCatalogLessonFailure,
} from "./catalog.actions.js";

export function* watchRefreshCatalog() {
  yield takeEvery(CatalogActionTypes.REFRESH_CATALOG, refreshCatalog);
}
export function* watchRefreshCatalogLesson() {
  yield takeEvery(
    CatalogActionTypes.REFRESH_CATALOG_LESSON,
    refreshCatalogLesson
  );
}

const getCatalogAsync = async () => {
  return await database
    .collection("catalogs")
    .get()
    .then((catalogs) => catalogs)
    .catch((error) => {
      console.log("error: " + error);
    });
};

export function* refreshCatalog() {
  try {
    const catalogRef = yield call(getCatalogAsync, {});
    const catalog = catalogRef.docs.reduce(
      (catalog, doc) => {
        const data = { id: doc.id, ...doc.data() };
        catalog.byID[doc.id] = data;

        // initialise array if category doesn't exist
        if (!catalog.byCategory.hasOwnProperty(data.category)) {
          catalog.byCategory[data.category] = [];
        }
        catalog.byCategory[data.category].push(data);

        return catalog;
      },
      { byID: {}, byCategory: {} }
    );
    yield put(refreshCatalogSuccess(catalog));
  } catch (error) {
    console.log(error);
    // todo send a failure action
  }
}

const getLessonAsync = async (id) => {
  return await database
    .doc(`catalogs/${id}`)
    .get()
    .then((doc) => doc)
    .catch((error) => {
      console.log("error: " + error);
    });
};

export function* refreshCatalogLesson({ payload }) {
  try {
    const lessonRef = yield call(getLessonAsync, payload.id);
    if (!lessonRef.exists) {
      yield put(refreshCatalogLessonFailure({ error: "Content not found" }));
      return;
    }

    yield put(
      refreshCatalogLessonSuccess({ ...lessonRef.data(), id: lessonRef.id })
    );
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([fork(watchRefreshCatalog), fork(watchRefreshCatalogLesson)]);
}
