import { TaskActionTypes } from "./tasks.types";

const INITIAL_STATE = {
  loading: false,
  byId: {},
};

const tasksReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TaskActionTypes.REFRESH_TASKS:
      return { ...state, loading: true };
    case TaskActionTypes.REFRESH_TASKS_SUCCESS:
      return { ...state, ...payload, loading: false };
    case TaskActionTypes.FETCH_TASK:
      return { ...state, loading: true };
    case TaskActionTypes.FETCH_TASK_SUCCESS:
      state.byId[payload.id] = payload;
      return { ...state, loading: false };
    // case TaskActionTypes.FETCH_TASK_FAILURE:
    //   return { ...state, loading: false };
    case TaskActionTypes.SUBMIT_TASK_RESPONSE:
      return { ...state };
    default:
      return { ...state };
  }
};

export default tasksReducer;
