import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import authUser from "./auth/reducer";
import user from "./user/user.reducer.js";
import catalog from "./catalog/catalog.reducer.js";
import tasks from "./tasks/tasks.reducer";
import wellbeingResults from "./wellbeingResults/wellbeingResults.reducer";
import taskSharedResults from "./taskSharedResults/taskSharedResults.reducer";

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  user,
  catalog,
  tasks,
  wellbeingResults,
  taskSharedResults,
});

export default reducers;
