import { all, fork, call, put, takeEvery } from "redux-saga/effects";
import { functions } from "../../helpers/Firebase";

import { WellbeingResultsActionTypes } from "./wellbeingResults.types.js";
import { fetchWellbeingResultsSuccess } from "./wellbeingResults.actions.js";

export function* watchFetchWellbeingResults() {
  yield takeEvery(
    WellbeingResultsActionTypes.FETCH_WELLBEING_RESULTS,
    fetchWellbeingResults
  );
}

const getResultAsync = async (taskId, method) => {
  const typeform = functions.httpsCallable(method);
  return await typeform({ taskId });
};

export function* fetchWellbeingResults({ payload: { taskId } }) {
  yield fork(fetchMyWellbeingResults, taskId);
  yield fork(fetchSharedWellbeingResults, taskId);
}

function* fetchMyWellbeingResults(taskId) {
  try {
    const results = yield call(getResultAsync, taskId, "typeform-responses");

    yield put(fetchWellbeingResultsSuccess("me", results.data));
  } catch (error) {
    console.error(error);
  }
}

function* fetchSharedWellbeingResults(taskId) {
  try {
    const results = yield call(
      getResultAsync,
      taskId,
      "typeform-sharedResponses"
    );

    yield put(fetchWellbeingResultsSuccess("shared", results.data));
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([fork(watchFetchWellbeingResults)]);
}
