/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [{ id: "en", name: "English", direction: "ltr" }];

export const firebaseConfig = {
  apiKey: "AIzaSyCEZoIBcCGXzjxvXKmKmSf7xQKJrweHxQI",
  authDomain: "switchboard-f2c9e.firebaseapp.com",
  databaseURL: "https://switchboard-f2c9e.firebaseio.com",
  projectId: "switchboard-f2c9e",
  storageBucket: "switchboard-f2c9e.appspot.com",
  messagingSenderId: "73032938415",
  appId: "1:73032938415:web:31e1420dedad73c001f55a",
  measurementId: "G-3BCDTWVZ7T",
};

export const searchPath = "/app/pages/search";
export const defaultColor = "brand.colors";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = false;
export const themeColorStorageKey = "__theme_color";
export const themeRadiusStorageKey = "__theme_radius";

export const missingVideoThumbnail = "/assets/img/missing-video-thumbnail.svg";
