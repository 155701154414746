import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";
import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "video.js/dist/video-js.css";
import "react-image-lightbox/style.css";

import { defaultColor } from "./constants/defaultValues";

let render = () => {
  import("./assets/css/sass/themes/switchboard." + defaultColor + ".scss").then(
    (x) => {
      require("./AppRenderer");
    }
  );
};
render();
