import { TaskActionTypes } from "./tasks.types";

export const refreshTasks = (userId) => ({
  type: TaskActionTypes.REFRESH_TASKS,
  payload: { userId },
});

export const refreshTasksSuccess = (tasks) => ({
  type: TaskActionTypes.REFRESH_TASKS_SUCCESS,
  payload: tasks,
});

export const fetchTask = (id) => ({
  type: TaskActionTypes.FETCH_TASK,
  payload: id,
});
export const fetchTaskSuccess = (task) => ({
  type: TaskActionTypes.FETCH_TASK_SUCCESS,
  payload: task,
});

export const submitTaskResponse = (userId, taskId, responseId, history) => ({
  type: TaskActionTypes.SUBMIT_TASK_RESPONSE,
  payload: {
    userId,
    taskId,
    responseId,
    history,
  },
});
