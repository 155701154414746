import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { database, auth } from "../../helpers/Firebase.js";

import { TaskSharedResultsTypes } from "./taskSharedResults.types.js";
import {
  addTaskSharedResultsSuccess,
  addTaskSharedResultsFailure,
  fetchTaskSharedResultsSuccess,
} from "./taskSharedResults.actions";

export function* watchAddTaskSharedResults() {
  yield takeEvery(
    TaskSharedResultsTypes.ADD_TASK_SHARED_RESULTS,
    addTaskSharedResults
  );
}

export function* watchFetchTaskSharedResults() {
  yield takeEvery(
    TaskSharedResultsTypes.FETCH_TASK_SHARED_RESULTS,
    fetchTaskSharedResults
  );
}

function getTaskSharedResultRef(taskId, sharerId, receiverId) {
  return database
    .collection(`users/${sharerId}/taskSharedResults/`)
    .doc(`${taskId}-${receiverId}`);
}

function getTaskReceivedResultRef(taskId, sharerId, receiverId) {
  return database
    .collection(`users/${receiverId}/taskReceivedResults/`)
    .doc(`${taskId}-${sharerId}`);
}

async function getReceiver(email) {
  const users = await database
    .collection(`users`)
    .where("email", "==", email)
    .get();

  if (users.empty) {
    // email validation should prevent getting here, so throw
    throw new Error("User not found!");
  }

  const receiver = users.docs[0];
  return receiver;
}

export function* addTaskSharedResults({ payload: { email, taskId } }) {
  try {
    const receiverDoc = yield call(getReceiver, email);
    const sharerId = auth.currentUser.uid;
    const sharedResultRef = getTaskSharedResultRef(
      taskId,
      sharerId,
      receiverDoc.id
    );

    const exists = yield call(
      async (sharedResultRef) => await sharedResultRef.get().exists,
      sharedResultRef
    );
    if (exists) {
      yield put(addTaskSharedResultsSuccess());
      return;
    }

    yield* batchAddShare(receiverDoc, taskId);

    const taskSharedResult = yield call(
      async () => await sharedResultRef.get(),
      sharedResultRef
    );
    yield put(addTaskSharedResultsSuccess(taskId, taskSharedResult.data()));
  } catch (error) {
    console.error(error);

    yield put(
      addTaskSharedResultsFailure(
        "Something unexpected happened! Try again or contact support."
      )
    );
  }
}

function* batchAddShare(receiverDoc, taskId) {
  const receiver = receiverDoc.data();
  const sharerId = auth.currentUser.uid;

  const batch = database.batch();

  // Add sharer
  const shareRef = getTaskSharedResultRef(taskId, sharerId, receiverDoc.id);
  yield call(
    async (batch, ref, data) => await batch.set(ref, data),
    batch,
    shareRef,
    {
      receiverId: receiverDoc.id,
      taskId,
      firstName: receiver.firstName,
      lastName: receiver.lastName,
      email: receiver.email,
    }
  );

  // Add receiver
  console.log("Add receiver");
  const receiveRef = getTaskReceivedResultRef(taskId, sharerId, receiverDoc.id);
  yield call(
    async (batch, ref, data) => await batch.set(ref, data),
    batch,
    receiveRef,
    {
      sharerId: sharerId,
      taskId,
    }
  );

  yield call(async () => batch.commit());
}

async function getTaskSharedResultsByTask(taskId) {
  return await database
    .collection(`users/${auth.currentUser.uid}/taskSharedResults/`)
    .where("taskId", "==", taskId)
    .get();
}
export function* fetchTaskSharedResults({ payload: { taskId } }) {
  try {
    const taskSharedResultsRef = yield call(getTaskSharedResultsByTask, taskId);
    const taskSharedResults = taskSharedResultsRef.docs.map(
      (taskSharedResult) => taskSharedResult.data()
    );
    console.log(taskSharedResults);
    yield put(fetchTaskSharedResultsSuccess(taskId, taskSharedResults));
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchAddTaskSharedResults),
    fork(watchFetchTaskSharedResults),
  ]);
}
