import { WellbeingResultsActionTypes } from "./wellbeingResults.types.js";

export const fetchWellbeingResults = (taskId) => ({
  type: WellbeingResultsActionTypes.FETCH_WELLBEING_RESULTS,
  payload: { taskId },
});
export const fetchWellbeingResultsSuccess = (type, results) => ({
  type: WellbeingResultsActionTypes.FETCH_WELLBEING_RESULTS_SUCCESS,
  payload: { type, results },
});
