import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { database } from "../../helpers/Firebase.js";

import { UserActionTypes } from "../user/user.types.js";
import { refreshUserSuccess } from "../user/user.actions.js";

export function* watchRefreshUser() {
  yield takeEvery(UserActionTypes.REFRESH_USER, refreshUser);
}

const getUserAsync = async (userId) => {
  return await database
    .doc(`users/${userId}`)
    .get()
    .then((user) => user)
    .catch((error) => {
      throw error;
    });
};

export function* refreshUser({ payload }) {
  try {
    const user = yield call(getUserAsync, payload.userId);
    if (user.exists) {
      yield put(refreshUserSuccess(user.data()));
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([fork(watchRefreshUser)]);
}
