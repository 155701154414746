import { TaskSharedResultsTypes } from "./taskSharedResults.types";

const INITIAL_STATE = {
  byTaskId: {},
  error: {},
};

const taskSharedResultsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TaskSharedResultsTypes.ADD_TASK_SHARED_RESULTS:
      return { ...state, error: {} };
    case TaskSharedResultsTypes.ADD_TASK_SHARED_RESULTS_SUCCESS:
      if (payload.taskSharedResult) {
        state.byTaskId[payload.taskId].push(payload.taskSharedResult);
      }
      return { ...state, error: {} };
    case TaskSharedResultsTypes.ADD_TASK_SHARED_RESULTS_FAILURE:
      return { ...state, error: payload.error };
    case TaskSharedResultsTypes.CLEAR_FORM_ERRORS:
      return { ...state, error: {} };
    case TaskSharedResultsTypes.FETCH_TASK_SHARED_RESULTS:
      return state;
    case TaskSharedResultsTypes.FETCH_TASK_SHARED_RESULTS_SUCCESS:
      state.byTaskId[payload.taskId] = payload.taskSharedResults;
      return state;
    default:
      return state;
  }
};

export default taskSharedResultsReducer;
