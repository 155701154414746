import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import userSagas from "./user/user.saga.js";
import catalogSagas from "./catalog/catalog.saga.js";
import tasksSagas from "./tasks/tasks.saga.js";
import wellbeingResultsSagas from "./wellbeingResults/wellbeingResults.saga.js";
import taskSharedResultsSagas from "./taskSharedResults/taskSharedResults.saga.js";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    userSagas(),
    catalogSagas(),
    tasksSagas(),
    wellbeingResultsSagas(),
    taskSharedResultsSagas(),
  ]);
}
