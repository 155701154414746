import { CatalogActionTypes } from "./catalog.types.js";

export const refreshCatalog = () => ({
  type: CatalogActionTypes.REFRESH_CATALOG,
  payload: {},
});
export const refreshCatalogSuccess = (catalog) => ({
  type: CatalogActionTypes.REFRESH_CATALOG_SUCCESS,
  payload: catalog,
});

export const refreshCatalogLesson = (id) => ({
  type: CatalogActionTypes.REFRESH_CATALOG_LESSON,
  payload: { id: id },
});
export const refreshCatalogLessonSuccess = (item) => ({
  type: CatalogActionTypes.REFRESH_CATALOG_LESSON_SUCCESS,
  payload: item,
});

export const refreshCatalogLessonFailure = (message) => ({
  type: CatalogActionTypes.REFRESH_CATALOG_LESSON_FAILURE,
  payload: { error: message },
});
