import { UserActionTypes } from "./user.types.js";

const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  initialized: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.REFRESH_USER:
      return state;
    case UserActionTypes.REFRESH_USER_SUCCESS:
      return { ...state, ...action.payload, initialized: true };
    default:
      return state;
  }
};

export default userReducer;
