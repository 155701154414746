import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { database } from "../../helpers/Firebase.js";

import { TaskActionTypes } from "./tasks.types";
import { refreshTasksSuccess, fetchTaskSuccess } from "./tasks.actions.js";

export function* watchRefreshTasks() {
  yield takeEvery(TaskActionTypes.REFRESH_TASKS, refreshTasks);
}
export function* watchFetchTask() {
  yield takeEvery(TaskActionTypes.FETCH_TASK, fetchTask);
}
export function* watchSubmitTaskResponse() {
  yield takeEvery(TaskActionTypes.SUBMIT_TASK_RESPONSE, submitTaskResponse);
}

const getTasksAsync = async () => {
  return await database
    .collection("tasks")
    .get()
    .then((tasks) => tasks)
    .catch((error) => {
      console.log("error: " + error);
    });
};

const getLatestResponsesAsync = async (userId) => {
  return await database
    .collection(`users/${userId}/latestResponses`)
    .get()
    .then((tasks) => tasks)
    .catch((error) => {
      console.log("error: " + error);
    });
};

export function* refreshTasks({ payload: { userId } }) {
  try {
    const tasksRef = yield call(getTasksAsync);
    const tasks = tasksRef.docs.reduce(
      (tasks, doc) => {
        tasks.byId[doc.id] = { ...doc.data(), id: doc.id };
        return tasks;
      },
      { byId: {} }
    );

    tasks.latestResponses = yield* getLatestResponses(userId);

    yield put(refreshTasksSuccess(tasks));
  } catch (error) {
    console.log(error);
    // TODO send failure action
  }
}

const getTaskAsync = async (id) => {
  return await database
    .doc(`tasks/${id}`)
    .get()
    .then((task) => task)
    .catch((error) => {
      console.log("error: " + error);
    });
};

function* getLatestResponses(userId) {
  const latestResponsesRef = yield call(getLatestResponsesAsync, userId);
  const latestResponses = {};
  latestResponsesRef.forEach((response) => {
    latestResponses[response.id] = response.data();
  });
  return latestResponses;
}

export function* fetchTask({ payload }) {
  try {
    const quesRef = yield call(getTaskAsync, payload);
    if (!quesRef.exists) {
      // todo return failure case
      return;
    }
    yield put(fetchTaskSuccess({ ...quesRef.data(), id: quesRef.id }));
  } catch (error) {
    console.log(error);
    // TODO send failure action
  }
}

const putTaskResponseAsync = async (userId, taskId, responseId) => {
  return await database
    .collection(`users/${userId}/latestResponses/`)
    .doc(taskId)
    .set({
      responseId: responseId,
      submittedAt: new Date(),
    })
    .then((latestResponse) => latestResponse);
};

export function* submitTaskResponse({
  payload: { userId, taskId, responseId, history },
}) {
  try {
    yield call(putTaskResponseAsync, userId, taskId, responseId);
  } catch (error) {
    console.log(error);
    // do nothing as we will still navigate
  }

  // always navigate, even on failure as we will fix the missed write
  // When we have more than one result type we will use a task property to generate the url e.g. each task defines a unique key and use the key like "/app/results/<key"
  // Will also need to remove the taskId parameter and use a lookup on the results page
  history.push(`/app/results/wellbeing?taskId=${taskId}`);
}

export default function* rootSaga() {
  yield all([
    fork(watchRefreshTasks),
    fork(watchFetchTask),
    fork(watchSubmitTaskResponse),
  ]);
}
