import { CatalogActionTypes } from "./catalog.types";

const INITIAL_STATE = {
  byID: {},
  byCategory: {},
};

const catalogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CatalogActionTypes.REFRESH_CATALOG:
      return { ...state, loading: true };
    case CatalogActionTypes.REFRESH_CATALOG_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    //TODO REFRESH_CATALOG_FAILURE
    case CatalogActionTypes.REFRESH_CATALOG_LESSON:
      return { ...state, loading: true };
    case CatalogActionTypes.REFRESH_CATALOG_LESSON_SUCCESS:
      state.byID[action.payload.id] = action.payload;
      return { ...state, loading: false };
    case CatalogActionTypes.REFRESH_CATALOG_LESSON_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default catalogReducer;
