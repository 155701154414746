import { UserActionTypes } from "./user.types";

export const refreshUser = (userId) => ({
  type: UserActionTypes.REFRESH_USER,
  payload: { userId: userId },
});

export const refreshUserSuccess = (user) => ({
  type: UserActionTypes.REFRESH_USER_SUCCESS,
  payload: user,
});
