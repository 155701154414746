import { TaskSharedResultsTypes } from "./taskSharedResults.types";

export const addTaskSharedResults = (taskId, email) => ({
  type: TaskSharedResultsTypes.ADD_TASK_SHARED_RESULTS,
  payload: { taskId, email },
});

export const addTaskSharedResultsSuccess = (taskId, taskSharedResult) => ({
  type: TaskSharedResultsTypes.ADD_TASK_SHARED_RESULTS_SUCCESS,
  payload: { taskId, taskSharedResult },
});

export const addTaskSharedResultsFailure = (message) => ({
  type: TaskSharedResultsTypes.ADD_TASK_SHARED_RESULTS_FAILURE,
  payload: { error: { message: message } },
});

export const clearFormErrors = () => ({
  type: TaskSharedResultsTypes.CLEAR_FORM_ERRORS,
  payload: {},
});

export const fetchTaskSharedResults = (taskId) => ({
  type: TaskSharedResultsTypes.FETCH_TASK_SHARED_RESULTS,
  payload: { taskId },
});

export const fetchTaskSharedResultsSuccess = (taskId, taskSharedResults) => ({
  type: TaskSharedResultsTypes.FETCH_TASK_SHARED_RESULTS_SUCCESS,
  payload: { taskId, taskSharedResults },
});
